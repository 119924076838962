.App {
  text-align: center;
}

@font-face {
  font-family: "Urbanist";
  src: url("./fonts/Urbanist-VariableFont_wght.ttf");
  font-weight: 1 999
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Urbanist";
}


h1 {
  font-size: 40px;
}

h2 {
  margin: 4px;
}

h3 {
  margin: 4px;
}

h4 {
  margin: 0px;
}

p {
  margin: 0;
}

input {
  border: none;
  background-color: #fff;
  border:1px solid lightgray;
  padding: 8px;
  font-size: 16px;
  border-radius: 8px;
  /* margin-block: 4px; */
}

input[type=submit] {
  -webkit-appearance: none;
  
}

input:disabled {
  background-color: #eaeaea;
}

select {
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHBvbHlnb24gY2xhc3M9ImNscy0yIiBwb2ludHM9IjEuNDEgNC42NyAyLjQ4IDMuMTggMy41NCA0LjY3IDEuNDEgNC42NyIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIzLjU0IDUuMzMgMi40OCA2LjgyIDEuNDEgNS4zMyAzLjU0IDUuMzMiLz48L3N2Zz4=) no-repeat 99% 50%;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  border: 1px solid lightgray;
  border-radius: 8px;
}

label {
  flex-direction: column;
  display: flex;
  font-size: 14px;
  font-weight: 700;
  gap:4px
  /* margin: 8px; */
}

.jobCard:hover {
  box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
}

.logoutButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: #667085;
  border: none;
  background: none;
  cursor: pointer;
  font-weight: 600;
  padding: 12px;
  min-width: 140px;
  font-size: 16px;
  gap:12px;
  transition: 0.1s;
}

.logoutButton:hover {
  background: #F6F6FF;
  color: #443df6
}

.logoutButton:active {
  color: #F6F6FF;
  background: #443df6
}

.noSelect {
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
}