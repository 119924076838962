table {
    border-collapse: collapse;
}

table tr {
  border-bottom: 1px solid #eaeaea;
}

table tr:last-child {
  border: 0;
}

table tr:hover {
  background-color: #fbfbfd;
  cursor: pointer;
}

th {
  padding: 4px;
  background-color: #F6F6FF;
}

th:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

th:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

td {
  padding-block: 16px;
  padding-inline: 14px;
  font-size: 14px;
  font-weight: 500;
}

.filter {
  margin: 4px;
  border-radius: 4px;
  padding: 6px;
  font-size: 16px;
  color: #8d98af;
  font-weight: 600;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0px;
  text-align: left;
  flex: 1;
  background: none;
  cursor: pointer;
  gap: 8px;
  max-lines: 1;
  white-space: none;
  /* width:100%; */
}

.filter:hover {
  background: #8d98af;
  color: #fff;
}

.sortSelector {
  background: #FFF;
  color: #443df6;
  font-weight: 600;
  font-size: 16px;
  text-align: left;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  padding-inline: 16px;
  padding-block: 12px;
}

.sortSelector:hover {
  background: #EFEFF4
}

.sortSelector:active {
  background: #F6F6FF
}

.pageButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  border-radius: 40px;
  border: none;
  background: none;
  cursor: pointer;
  color: inherit
}

.pageButton:hover {
  background: #EFEFF4
}

.pageButton:disabled {
  background: none;
  color: #667085;
  cursor: default;
  opacity: 0.5;
}

.pageButton:active {
  opacity: 0.5
}

.addButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px;
  border-radius: 40px;
  border: none;
  background: #F6F6FF;
  cursor: pointer;
  color: #443df6;
  border: 1px solid #443df6
}

.addButton:hover {
  background: #443df6;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  color: #fff
}

.addButton:active {
  opacity: 0.5
}